export const initialColumns = {
  waiting: {
    status: 'waiting',
    title: 'Waiting',
  },
  investigating: {
    status: 'investigating',
    title: 'Investigating',
  },
  building: {
    status: 'building',
    title: 'Building',
  },
  done: {
    status: 'done',
    title: 'Done',
  },
}
