import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useWidgetApi } from 'src/api/hooks/apiWidget'
import {
  FormColorPicker,
  FormInput,
  FormWrapper,
} from 'src/shared/components/FormWrapper'
import { Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Separator,
} from 'src/shared/components/ui'
import { DialogHeader } from 'src/shared/components/ui/dialog'
import { useAuth } from 'src/shared/hooks/authProvider'
import { z } from 'zod'

import { BoardCard } from './components/BoardCard'

const formSchema = z.object({
  name: z.string().min(2).max(50),
  color: z.string(),
})

export const BoardsPage = () => {
  const { orgId } = useOrgIdApi()
  const { session } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { addBoard, boards } = useBoardsApi({ orgId })
  const { createWidget } = useWidgetApi()

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      color: '',
    },
  })

  const addBoardSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await addBoard.mutateAsync({
      name: values.name,
      color: values.color,
      org_id: orgId || '',
      profile_id: session?.user.id || '',
    })

    if (result) {
      const newBoardId = result

      await createWidget.mutateAsync({
        board_id: newBoardId,
        chat_name: '',
        feature_requests: true,
        news: true,
        organization_id: orgId || '',
      })
    }

    setIsModalOpen(false)
    methods.reset()
  }

  return (
    <>
      <Card className="w-full h-full p-6">
        <Card className="justify-between items-center p-2">
          <Row className="justify-between items-center w-full">
            <h1 className="text-2xl font-normal">Boards</h1>

            <Button onClick={() => setIsModalOpen(true)}>Add new board</Button>
          </Row>
        </Card>

        {!boards?.data?.length && (
          <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
            <p className="text-lg text-gray-600">
              You don't have any boards yet.
            </p>
            <p className="text-md text-gray-500 mt-2">
              Click "Add new board" to get started!
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          {boards?.data?.map((board) => <BoardCard board={board} />)}
        </div>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-lg">
          <DialogHeader>Add new board</DialogHeader>
          <Separator />

          <FormWrapper onSubmit={addBoardSubmit} methods={methods}>
            <FormInput
              label="Name"
              placeholder="Project name"
              name="name"
              methods={methods}
            />

            <FormColorPicker name="color" label="Color" methods={methods} />

            <Button
              type="submit"
              style={{
                marginTop: '1rem',
              }}
            >
              Add board
            </Button>
          </FormWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}
