import { useParams } from 'react-router-dom'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Board } from '../Board/components/Board'
import { LoginOverlay } from './LoginScreen'

export default function PublicBoardPage() {
  const { session, loading } = useAuth()
  const { board_id } = useParams()

  return (
    <Column className="h-screen flex items-center justify-center p-4">
      <Card className="relative">
        {!loading && !session?.user && (
          <Column
            c
            className="absolute z-40 p-4 rounded-2x bg-white bg-opacity-60"
            style={{
              width: 'calc(100% - 40px)',
              height: 'calc(100% - 40px)',
            }}
          >
            <LoginOverlay />
          </Column>
        )}

        <Board boardId={board_id} />
      </Card>
    </Column>
  )
}
