import { useQuery } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useAuth } from 'src/shared/hooks/authProvider'

export const useOrgIdApi = () => {
  const supabase = useSupabaseClient()
  const { session } = useAuth()

  const { data: p_o_r } = useQuery({
    queryKey: ['p_o_r'],
    queryFn: async () =>
      await supabase
        .from('profiles_organizations_roles')
        .select('*')
        .filter('profile_id', 'eq', session?.user?.id),
    enabled: !!session?.user?.id,
  })

  const orgId = p_o_r?.data?.length && p_o_r?.data[0].organization_id

  return {
    orgId,
  }
}
