import React, { useState } from 'react'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { paths } from 'src/routes/paths'
import { Avatar } from 'src/shared/components/ui/avatar'
import { cn } from 'src/shared/components/ui/utils'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Logo } from './components/Logo'
import { LogoIcon } from './components/LogoIcon'
import { links } from './consts'
import { Sidebar, SidebarBody, SidebarLink } from './sidebar'

const avatar =
  'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.BitMB_DGEBBIrVLqrvTnBQHaFP%26pid%3DApi&f=1&ipt=cb39a1219b1bd344fdee9352549c337d5ab3980b13cf2037e9d1b71154a2a2da&ipo=images'

export function SidebarDemo({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false)
  const { session } = useAuth()
  const { profile } = useProfilesApi(session?.user?.id)

  return (
    <div
      className={cn(
        'rounded-md flex flex-col md:flex-row w-full flex-1  mx-auto border border-neutral-200 dark:border-neutral-700 overflow-hidden',
        'h-screen'
      )}
    >
      <Sidebar open={open} setOpen={setOpen}>
        <SidebarBody className="justify-between gap-10">
          <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {open ? <Logo /> : <LogoIcon />}
            <div className="mt-8 flex flex-col gap-2">
              {links.map((link, idx) => (
                <SidebarLink key={idx} link={link} />
              ))}
            </div>
          </div>

          <div>
            <SidebarLink
              link={{
                label:
                  `${profile?.data?.first_name} ${profile?.data?.last_name}` ||
                  'Click to add info',
                href: paths.settingsPage,
                icon: (
                  <Avatar size="sm" src={profile?.data?.avatar_url || avatar} />
                ),
              }}
            />
          </div>
        </SidebarBody>
      </Sidebar>

      <div className="flex h-full flex-col flex-1 py-10 p-14 justify-center items-center">
        {children}
      </div>
    </div>
  )
}
