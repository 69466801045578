import { ThumbsUp } from 'lucide-react'
import { FeatureSuggestion_ } from 'src/api/hooks/apiFeatureSuggestions'
import { useFeatureSuggestionsVotesApi } from 'src/api/hooks/apiFeatureSuggestionsVotes'
import { Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui/button'

export const VotingButton = ({
  featureSuggestion,
  profile_id,
  score,
}: {
  featureSuggestion: FeatureSuggestion_
  profile_id
  score?
}) => {
  const { addFeatureSuggestionVote } = useFeatureSuggestionsVotesApi()

  const handleVote = () => {
    // Instead of upsert
    const hasVoted = featureSuggestion.feature_suggestions_votes.some(
      (vote) => vote.profile_id === profile_id
    )
    if (!hasVoted) {
      addFeatureSuggestionVote.mutate({
        feature_suggestions_id: featureSuggestion.id,
        score: 1,
        profile_id,
      })
    }
  }

  const VotingButton = () => (
    <Button variant="light" size="sm" className="p-0" onClick={handleVote}>
      <ThumbsUp
        style={{
          width: 18,
          height: 18,
          fill: score ? 'black' : 'white',
        }}
      />

      <Row className="gap-1 ml-1">
        <p className="text-small">{score}</p>
      </Row>
    </Button>
  )

  return <VotingButton />
}
