import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pen, Save, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useFeatureSuggestionChatMessagesApi } from 'src/api/hooks/apiFeatureSuggestionsComment'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Input } from 'src/shared/components/ui/input'

dayjs.extend(relativeTime)

export const Msg = ({ message, canEdit }) => {
  const [isEditing, setIsEditing] = useState(false)
  const { updateFeatureSuggestionComment, deleteFeatureSuggestionComment } =
    useFeatureSuggestionChatMessagesApi()

  const [message_, setMessage] = useState(message.message)

  return (
    <Column className="p-3 rounded-2xl bg-gray-100 mb-2 gap-1">
      <Row className="justify-between">
        <p className="text-xs text-gray-500">{message.profiles?.email}</p>

        {canEdit && (
          <Row className="gap-2">
            {isEditing ? (
              <Save
                className="w-3 h-3 cursor-pointer"
                onClick={() => {
                  updateFeatureSuggestionComment.mutate({
                    id: message.id,
                    message: message_,
                  })
                  setIsEditing(false)
                }}
              />
            ) : (
              <Pen
                className="w-3 h-3 cursor-pointer"
                onClick={() => setIsEditing(true)}
              />
            )}
            <Trash2
              className="w-3 h-3 cursor-pointer"
              onClick={() => {
                deleteFeatureSuggestionComment.mutate(message.id)
              }}
            />
          </Row>
        )}
      </Row>

      {isEditing ? (
        <Input
          value={message_}
          onChange={(e) => {
            setMessage(e.target.value)
            updateFeatureSuggestionComment.mutate({
              id: message.id,
              message: message_,
            })
          }}
        />
      ) : (
        <p className="text-xs">{message_}</p>
      )}

      <p className="text-xs text-gray-500">
        {dayjs(message.created_at).toNow()}
      </p>
    </Column>
  )
}
