import { MessageSquare, Trash2, Video } from 'lucide-react'
import { KeyboardEvent, useState } from 'react'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui/card'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'
import { Textarea } from 'src/shared/components/ui/textarea'

import { useNewsApi } from '../../../api/hooks/apiNews'
import { LeftBubble } from '../../../shared/components/Widget/compontents/LeftBubble'
import { Button } from '../../../shared/components/ui/button'
import { VideoPost } from './VideoPost'

export const NewsTabs = ({ boardId }) => {
  const [post, setPost] = useState('')
  const { news, addPost, deletePost } = useNewsApi(boardId)
  const { profiles } = useProfilesApi()
  const user = profiles?.data?.[0]

  const onClickAddPost = () => {
    addPost.mutate({
      text_content: post,
      type: 'post',
      board_id: boardId || '',
      profile_id: user?.id || '',
    })
    setPost('')
  }

  const onClickAddVideo = () => {
    addPost.mutate({
      text_content: post,
      type: 'video',
      board_id: boardId || '',
      profile_id: user?.id || '',
    })
    setPost('')
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      post.includes('http') ? onClickAddVideo() : onClickAddPost()
    }
  }

  const messages = news?.data?.map((post) => {
    return (
      <div key={post.id} className="flex justify-between">
        {post.type === 'video' ? (
          <VideoPost
            key={post.id}
            src={post.text_content || ''}
            created_at={post.created_at}
          />
        ) : (
          <LeftBubble
            key={post.id}
            created_at={post.created_at}
            text_content={post.text_content || ''}
            title={user?.first_name || ''}
            avatarUrl={user?.avatar_url}
          />
        )}

        <Button
          variant="light"
          className="mt-2"
          size="sm"
          onClick={() => deletePost.mutate(post.id)}
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      </div>
    )
  })

  return (
    <Column c className="gap-4">
      <Card className="w-[500px]">
        <ScrollArea className="h-[60vh]" scrollbarHide>
          {messages}
        </ScrollArea>

        <div className="h-[130px]">
          <Textarea
            className="mt-3"
            placeholder="Enter a post / Post a video URL"
            value={post}
            onChange={(e) => setPost(e.target.value)}
            // @ts-ignore
            onKeyDown={handleKeyPress}
          />

          <Row className="gap-2">
            <Button className="w-full mt-4" onClick={onClickAddPost}>
              Add post <MessageSquare className="ml-2 h-4 w-4" />
            </Button>

            {post.includes('http') && (
              <Button
                className="w-full mt-4 bg-blue-500 hover:bg-blue-600 text-white"
                onClick={onClickAddVideo}
              >
                Add video <Video className="ml-2 w-4 h-4" />
              </Button>
            )}
          </Row>
        </div>
      </Card>
    </Column>
  )
}
