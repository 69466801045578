import { Dialog, DialogContent, Separator } from 'src/shared/components/ui'
import { DialogHeader } from 'src/shared/components/ui/dialog'
import { useAuth } from 'src/shared/hooks/authProvider'

import { AddSuggestion } from './AddSuggestion'

export const NewSuggestionModal = ({
  isModalOpen,
  setIsModalOpen,
  boardId,
}) => {
  const { session } = useAuth()
  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogContent className="max-w-lg">
        <DialogHeader>Add new suggestion</DialogHeader>
        <Separator />

        <AddSuggestion
          boardId={boardId || ''}
          profileId={session?.user?.id || ''}
          setIsModalOpen={setIsModalOpen}
        />
      </DialogContent>
    </Dialog>
  )
}
