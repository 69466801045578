import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui/card'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

export const LeadsTab = ({ boardId }) => {
  const { profiles } = useProfilesApi()

  return (
    <Column c className="gap-4 h-[85vh]">
      <Card className="w-[800px]">
        <ScrollArea className="h-[60vh]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Email</TableHead>
                <TableHead>Updated At</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {profiles?.data?.map((profile) => (
                <TableRow key={profile.id}>
                  <TableCell>{profile.email}</TableCell>
                  <TableCell>
                    {/* {format(new Date(profile?.updated_at || ''), 'PPP')} */}
                    {profile?.updated_at}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell className="font-bold">Total</TableCell>
                <TableCell className="font-bold">
                  {profiles?.data?.length || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    </Column>
  )
}
