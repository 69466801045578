import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'

import { useAuth } from '../../shared/hooks/authProvider'
import { paths } from '../paths'

export const ProtectedRoute = ({ component, ...args }: any) => {
  const { session, loading } = useAuth()
  const navigate = useNavigate()
  const { profile } = useProfilesApi(session?.user?.id)

  const goToOnboarding = !profile?.data?.completed_onboarding

  useMemo(() => {
    if (goToOnboarding) {
      navigate(paths.onboarding)
    }
  }, [goToOnboarding])

  useEffect(() => {
    if (!loading && !session?.user) {
      navigate(paths.loginSignUp)
    }
  }, [session, profile?.data])

  const Component = component

  return <Component {...args} />
}
