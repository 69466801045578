import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Avatar } from 'src/shared/components/ui/avatar'

dayjs.extend(relativeTime)

export const LeftBubble = ({
  title,
  created_at,
  text_content = '',
  avatarUrl,
}: {
  title: string
  created_at: string
  text_content: string
  avatarUrl?: any
}) => {
  return (
    <div className="row w-full">
      <div className="p-2 flex gap-2 w-full">
        <Avatar className="w-6 h-6 text-tiny" src={avatarUrl} />

        <div className="text-sm py-2 px-4 shadow rounded-e-xl rounded-es-xl border border-[hsla(0, 0, 0, 0.18)] min-w-[60%]">
          <div className="col py-1">
            <div className="col mb-2">
              <span className="text-sm font-normal mr-2">{title}</span>
              <span className="text-xs font-normal ">
                {dayjs(created_at).toNow()}
              </span>
            </div>
          </div>

          {text_content}
        </div>
      </div>
    </div>
  )
}
