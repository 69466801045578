import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFeatureSuggestionsApi } from 'src/api/hooks/apiFeatureSuggestions'
import {
  FormInput,
  FormTextarea,
  FormWrapper,
} from 'src/shared/components/FormWrapper'
import { ScreenshotTool } from 'src/shared/components/Widget/compontents/Suggestions/components/ScreenshotTool'
import { Button } from 'src/shared/components/ui'
import { z } from 'zod'

const formSchema = z.object({
  title: z.string().min(2).max(100),
  description: z.string().optional(),
  imageUrls: z.string().optional(),
})

export const AddSuggestion = ({
  boardId,
  profileId,
  setIsModalOpen,
}: {
  boardId: string
  profileId: string
  setIsModalOpen: (isModalOpen: boolean) => void
}) => {
  const { addFeatureSuggestion } = useFeatureSuggestionsApi(boardId)

  const [isModalOpen, setModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      imageUrls: '',
    },
  })

  const suggestFeature = async (values: z.infer<typeof formSchema>) => {
    await addFeatureSuggestion.mutateAsync({
      title: values.title,
      description: values.description,
      board_id: boardId,
      profile_id: profileId,
      image_urls: values.imageUrls,
    })
    setIsModalOpen(false)
    methods.reset()
  }

  useEffect(() => {
    if (imageUrl) {
      methods.setValue('imageUrls', imageUrl)
    }
  }, [imageUrl])

  return (
    <>
      <ScreenshotTool
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        setImageUrl={setImageUrl}
      />

      <FormWrapper onSubmit={suggestFeature} methods={methods}>
        <FormInput
          label="Title"
          placeholder="Add payment solution"
          name="title"
          methods={methods}
        />

        <FormTextarea
          label="Description"
          placeholder="I would really need a way to accept payments from my customers."
          name="description"
          methods={methods}
        />

        <FormInput
          name="imageUrls"
          label="Images"
          placeholder="https://images/img1.png, https://images/img2.png"
          methods={methods}
        />
      </FormWrapper>

      <Button
        type="submit"
        style={{
          marginTop: '1rem',
        }}
        onClick={methods.handleSubmit(suggestFeature)}
      >
        Suggest feature
      </Button>
    </>
  )
}
