import { Column } from 'src/shared/components/Semantic/all'
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'src/shared/components/ui/dialog'
import { Image } from 'src/shared/components/ui/image'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'

export const Modal = ({
  isOpen,
  onOpenChange,
  images,
}: {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  images?: string[]
}) => (
  <Dialog open={isOpen} onOpenChange={onOpenChange}>
    <DialogContent>
      <DialogHeader>Gallery</DialogHeader>

      <ScrollArea className="max-h-[75vh]">
        <Column className="gap-4">
          {images?.map((image, i) => (
            <Image
              className="rounded-md w-full"
              key={i}
              alt="Gallery Image"
              src={image}
            />
          ))}
        </Column>
      </ScrollArea>
    </DialogContent>
  </Dialog>
)
