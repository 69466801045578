import { zodResolver } from '@hookform/resolvers/zod'
import { Save, Trash2 } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useWidgetApi } from 'src/api/hooks/apiWidget'
import { paths } from 'src/routes/paths'
import {
  FormCheckbox,
  FormInput,
  FormWrapper,
} from 'src/shared/components/FormWrapper'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Code, ScrollArea } from 'src/shared/components/ui'
import { z } from 'zod'

import { getCodeSnippets } from './consts'

const formSchema = z.object({
  // Board settings
  name: z.string().min(2).max(50),
  // Widget settings
  feature_requests: z.boolean(),
  news: z.boolean(),
})

export const SettingsTab = ({ boardId }) => {
  const navigate = useNavigate()
  const { updateWidget, widget } = useWidgetApi(boardId)
  const { board, updateBoard, deleteBoard } = useBoardsApi({
    boardId,
  })
  const { widgetCode, embedCode } = getCodeSnippets(boardId || '')

  const onSubmitWidgetSettings = (values: z.infer<typeof formSchema>) =>
    updateWidget.mutate({
      id: widget?.data?.id || 0,
      feature_requests: values.feature_requests,
      news: values.news,
    })

  const onSubmitBoardSettings = (values: z.infer<typeof formSchema>) => {
    updateBoard.mutate({
      id: boardId || '',
      name: values.name,
    })
  }

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // Board settings
      name: '',
      // Widget settings
      feature_requests: false,
      news: false,
    },
  })

  useEffect(() => {
    if (board?.data) {
      methods.setValue('name', board?.data?.name || '')
    }

    if (widget?.data) {
      methods.setValue(
        'feature_requests',
        widget?.data?.feature_requests || false
      )
      methods.setValue('news', widget?.data?.news || false)
    }
  }, [board?.data, widget?.data])

  return (
    <>
      <ScrollArea scrollbarHide className="h-[85vh]">
        <Column c className="gap-4">
          <Card className="w-[700px] justify-center items-center">
            <Column className="gap-2">
              <h2 className="text-xl font-normal">Board settings</h2>

              <Column className="gap-2">
                <FormWrapper onSubmit={onSubmitBoardSettings} methods={methods}>
                  <FormInput name="name" label="Board name" methods={methods} />

                  <Row className="justify-between">
                    <Button type="submit">
                      <Save className="w-4 h-4 mr-2" />
                      Save
                    </Button>

                    <Button
                      variant="outline"
                      className="w-fit"
                      onClick={() => {
                        deleteBoard.mutate(boardId || '')
                        navigate(paths.boards)
                      }}
                    >
                      <Trash2 className="w-4 h-4 mr-2 text-red-500" />
                      Remove board
                    </Button>
                  </Row>
                </FormWrapper>
              </Column>
            </Column>
          </Card>

          <Card className="w-[700px] justify-center items-center">
            <Column className="gap-2">
              <h2 className="text-xl font-normal mb-2">Widget settings</h2>

              <FormWrapper onSubmit={onSubmitWidgetSettings} methods={methods}>
                <FormCheckbox
                  name="feature_requests"
                  label="Feature requests"
                  methods={methods}
                />
                <FormCheckbox name="news" label="News" methods={methods} />

                <Button style={{ marginTop: '1rem' }} type="submit">
                  <Save className="w-4 h-4 mr-2" />
                  Save
                </Button>
              </FormWrapper>
            </Column>
          </Card>

          <Card className="w-[700px] justify-center items-center">
            <Column className="gap-2">
              <h2 className="text-xl font-normal mb-2">Embed widget</h2>

              <Code>{widgetCode}</Code>

              <Row className="gap-2">
                <p className="italic">Paste this code into your</p>
                <Code className="italic">index.html</Code>
                <p className="italic">within the</p>
                <Code className="italic">{`<body>`}</Code>
                <p className="italic">tag</p>
              </Row>
            </Column>
          </Card>

          <Card className="w-[700px] justify-center items-center">
            <Column className="gap-2">
              <h2 className="text-xl font-normal mb-2">Embed board</h2>

              <Code>{embedCode}</Code>
              <p className="italic">Paste this code into your site</p>
            </Column>
          </Card>
        </Column>
      </ScrollArea>
    </>
  )
}
