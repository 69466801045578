import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CircleCheck } from 'lucide-react'
import { useSupabaseClient } from 'src/api/supabase'
import { Row } from 'src/shared/components/Semantic/all'
import { useAuth } from 'src/shared/hooks/authProvider'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

export type InsertProfiles = Database['public']['Tables']['profiles']['Insert']

export const useProfilesApi = (profileId_?: string) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const profileId = useAuth().session?.user?.id

  const { toast } = useToast()

  const { data: currentProfile } = useQuery({
    queryKey: ['currentProfile', profileId],
    queryFn: async () =>
      await supabase.from('profiles').select('*').filter('id', 'eq', profileId),
    enabled: !!profileId,
  })

  const { data: profile } = useQuery({
    queryKey: ['profile', profileId_],
    queryFn: async () =>
      await supabase
        .from('profiles')
        .select('*')
        .filter('id', 'eq', profileId_)
        .single(),
    enabled: !!profileId_,
  })

  const { data: profiles } = useQuery({
    queryKey: ['profiles', profileId_],
    queryFn: async () => await supabase.from('profiles').select('*'),
  })

  const updateProfile = useMutation({
    mutationFn: async ({
      first_name,
      last_name,
      email,
      avatar_url,
      completed_onboarding,
    }: InsertProfiles) =>
      await supabase
        .from('profiles')
        .update({
          ...(first_name && { first_name }),
          ...(last_name && { last_name }),
          ...(email && { email }),
          ...(avatar_url && { avatar_url }),
          ...(completed_onboarding && { completed_onboarding }),
        })
        .eq('id', profileId as string),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles'] })

      toast({
        variant: 'success',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <CircleCheck className="h-4 w-4" />
            Updated
          </Row>
        ),
      })
    },
  })

  return {
    currentProfile,
    profile,
    profiles,
    updateProfile,
  }
}
