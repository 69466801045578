import { QueryClientProvider } from '@tanstack/react-query'
import { X } from 'lucide-react'
import { useRef, useState } from 'react'
import { queryClient } from 'src/App'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { useWidgetApi } from 'src/api/hooks/apiWidget'
import { SupabaseProvider } from 'src/api/supabase'
import { LoginOverlay } from 'src/pages/PublicBoard/LoginScreen'
import { Avatar } from 'src/shared/components/ui/avatar'
import { Button } from 'src/shared/components/ui/button'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Column } from '../Semantic/all'
import { Card } from '../ui/card'
import { News } from './compontents/News'
import { Suggestions } from './compontents/Suggestions'
import { TabKey, navItems } from './consts'
import logoImage from './logo.png'

export const Widget = ({ boardId }) => {
  const { widget } = useWidgetApi(boardId)
  const { session, loading } = useAuth()
  const { profile } = useProfilesApi(session?.user.id)

  const [isOpen, setIsOpen] = useState(false)
  const [tab, setTab] = useState<TabKey>('featureRequests')
  const divRef = useRef(null)

  const ContentContainer = ({ children }: { children: any }) => (
    <Card className="absolute bottom-20 right-3 z-50 rounded-2xl w-[400px] h-[500px] overflow-hidden glass">
      {children}
    </Card>
  )

  const WidgetTrigger = () => (
    <Card
      ref={divRef}
      className="absolute bottom-4 right-4 row-c gap-0 rounded-full p-2 glass"
      id="getuservotes-widget"
    >
      {isOpen ? (
        <>
          {navItems.map(({ tabName, icon }, key) => {
            if (
              tabName === 'featureRequests' &&
              !widget?.data?.feature_requests
            )
              return null
            if (tabName === 'news' && !widget?.data?.news) return null

            return (
              <Button
                radius="full"
                variant="light"
                size="icon"
                onClick={() => setTab(tabName as TabKey)}
              >
                {icon}
              </Button>
            )
          })}

          <Button
            radius="full"
            variant="light"
            size="icon"
            onClick={() => setIsOpen(false)}
          >
            <X />
          </Button>
        </>
      ) : (
        <Button
          radius="full"
          variant="light"
          size="icon"
          onClick={() => setIsOpen(true)}
        >
          {profile?.data?.avatar_url ? (
            <Avatar src={profile?.data?.avatar_url} className="rounded-full" />
          ) : (
            <img src={logoImage} alt="Logo" className="rounded-full" />
          )}
        </Button>
      )}
    </Card>
  )

  return (
    <QueryClientProvider client={queryClient}>
      <SupabaseProvider>
        {isOpen && (
          <div ref={divRef} id="widget-container">
            <ContentContainer>
              {!loading && !session?.user && (
                <Column
                  c
                  className="absolute z-40 p-4 rounded-2x bg-white bg-opacity-60"
                  style={{
                    width: 'calc(100% - 40px)',
                    height: 'calc(100% - 40px)',
                  }}
                >
                  <LoginOverlay />
                </Column>
              )}
              <>
                <News show={tab === 'news'} boardId={boardId} />
                <Suggestions
                  show={tab === 'featureRequests'}
                  boardId={boardId}
                  profile_id={profile?.data?.id || ''}
                />
              </>
            </ContentContainer>
          </div>
        )}

        <WidgetTrigger />
      </SupabaseProvider>
    </QueryClientProvider>
  )
}
