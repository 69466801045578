import { Newspaper, Trello } from 'lucide-react'

export type TabKey = 'news' | 'featureRequests'

export const navItems = [
  {
    tabName: 'featureRequests',
    icon: <Trello className="size-5 text-primary" />,
  },
  {
    tabName: 'news',
    icon: <Newspaper className="size-5 text-primary" />,
  },
]
