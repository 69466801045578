import { ArrowRight } from 'lucide-react'
import { KeyboardEvent, useState } from 'react'
import {
  FeatureSuggestionChatMessage,
  useFeatureSuggestionChatMessagesApi,
} from 'src/api/hooks/apiFeatureSuggestionsComment'
import { Row } from 'src/shared/components/Semantic/all'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/shared/components/ui/accordion'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'

import { Msg } from './Msg'

export const ChatSection = ({ featureSuggestion, isMyBoard, profile_id }) => {
  const { addFeatureSuggestionComment } = useFeatureSuggestionChatMessagesApi()
  const [message, setMessage] = useState('')

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onClick()
  }

  const onClick = () => {
    addFeatureSuggestionComment.mutate({
      message,
      type: isMyBoard ? 'admin' : 'user',
      profile_id,
      feature_suggestion_id: featureSuggestion.id,
    })
    setMessage('')
  }

  return (
    <Accordion type="single" collapsible>
      <AccordionItem
        onClick={() => {
          const element = document.getElementById('scroll')
          element?.scrollBy({
            top: 1000,
          })
        }}
        value="1"
      >
        <AccordionTrigger className="w-full">
          <p className="text-xs font-normal text-default-500">{`${featureSuggestion.feature_suggestion_chat_messages?.length} comments`}</p>
        </AccordionTrigger>

        <AccordionContent className="mt-2">
          <ScrollArea
            id="scroll"
            className={`${
              featureSuggestion.feature_suggestion_chat_messages.length === 0
                ? ''
                : featureSuggestion.feature_suggestion_chat_messages.length ===
                  1
                ? 'h-[100px]'
                : 'h-[200px]'
            } gap-2`}
          >
            {featureSuggestion.feature_suggestion_chat_messages?.map(
              (message: FeatureSuggestionChatMessage, i) => {
                return (
                  <Msg
                    key={i}
                    message={message}
                    canEdit={message.profile_id === profile_id}
                  />
                )
              }
            )}
          </ScrollArea>

          <Row className="gap-2 mt-2 w-full">
            <Input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyPress}
            />

            <Button onClick={onClick}>
              <ArrowRight className="w-4 h-4" />
            </Button>
          </Row>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
