import { zodResolver } from '@hookform/resolvers/zod'
import { ExternalLink, MonitorPlay, PlusCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFeatureSuggestionsApi } from 'src/api/hooks/apiFeatureSuggestions'
import { paths } from 'src/routes/paths'
import {
  FormInput,
  FormTextarea,
  FormWrapper,
} from 'src/shared/components/FormWrapper'
import { Card } from 'src/shared/components/ui'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from 'src/shared/components/ui/accordion'
import { Button } from 'src/shared/components/ui/button'
import { z } from 'zod'

import { ScreenshotTool } from './ScreenshotTool'

const formSchema = z.object({
  title: z.string().min(2).max(50),
  description: z.string().min(2).max(50),
  imageUrls: z.string().optional(),
})

export const AddSuggestion = ({
  boardId,
  profile_id,
  modal,
}: {
  boardId: any
  profile_id: any
  modal?: boolean
}) => {
  const { addFeatureSuggestion } = useFeatureSuggestionsApi(boardId)
  const [isModalOpen, setModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [accordionValue, setAccordionValue] = useState<string | undefined>(
    modal ? 'item-1' : undefined
  )

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      imageUrls: '',
    },
  })

  const suggestFeature = async (values: z.infer<typeof formSchema>) => {
    await addFeatureSuggestion.mutateAsync({
      title: values.title,
      description: values.description,
      profile_id,
      image_urls: values.imageUrls,
      board_id: boardId,
    })
    setAccordionValue(undefined)
    methods.reset()
  }

  useEffect(() => {
    if (imageUrl) {
      methods.setValue('imageUrls', imageUrl)
    }
  }, [imageUrl])

  return (
    <>
      <ScreenshotTool
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        setImageUrl={setImageUrl}
      />

      <Accordion
        className="w-full"
        type="single"
        collapsible
        value={accordionValue}
        onValueChange={setAccordionValue}
      >
        <AccordionItem value="item-1">
          {!modal && (
            <Card className="flex justify-between items-center p-0">
              <Button
                variant="ghost"
                onClick={() =>
                  setAccordionValue(
                    accordionValue === 'item-1' ? undefined : 'item-1'
                  )
                }
              >
                <PlusCircleIcon
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  className="mr-3"
                />
                <p>Add suggestion</p>
              </Button>

              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_FE_SERVER_URL}${paths.publicBoard}/${boardId}`,
                    '_blank'
                  )
                }}
              >
                <ExternalLink
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              </Button>
            </Card>
          )}

          <AccordionContent className="w-full flex flex-col mt-3">
            <FormWrapper onSubmit={suggestFeature} methods={methods}>
              <FormInput
                label="Title"
                placeholder="Add payment solution"
                name="title"
                methods={methods}
              />

              <FormTextarea
                label="Description"
                placeholder="I would really need a way to accept payments from my customers."
                name="description"
                methods={methods}
              />

              <FormInput
                name="imageUrls"
                label="Images"
                placeholder="https://images/img1.png, https://images/img2.."
                methods={methods}
                endContent={
                  !modal && (
                    <Button
                      type="button"
                      size="sm"
                      variant="ghost"
                      onClick={() => setModalOpen(true)}
                    >
                      <MonitorPlay className="w-4 h-4" />
                    </Button>
                  )
                }
              />
            </FormWrapper>

            <Button
              type="submit"
              style={{
                marginTop: '1rem',
              }}
              onClick={methods.handleSubmit(suggestFeature)}
            >
              Suggest feature
            </Button>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  )
}
