import { useMemo } from 'react'
import { Column } from 'src/shared/components/Semantic/all'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'

import { useFeatureSuggestionsApi } from '../../../../../api/hooks/apiFeatureSuggestions'
import { Suggestion } from '../../../Suggestion'
import { AddSuggestion } from './components/AddSuggestion'

export const Suggestions = ({
  show = true,
  boardId,
  profile_id,
}: {
  show?: boolean
  boardId?: any
  profile_id: string
}) => {
  const { feature_suggestions } = useFeatureSuggestionsApi(boardId)

  // Calculate mean score and create a new array with the mean score
  const data: any = useMemo(
    () =>
      feature_suggestions?.data?.map((featureSuggestion) => {
        let mean = 0
        if (featureSuggestion?.feature_suggestions_votes?.length) {
          const score = featureSuggestion.feature_suggestions_votes.reduce(
            (acc, vote) => acc + (vote?.score || 0),
            0
          )
          mean = score / featureSuggestion.feature_suggestions_votes.length
          mean = Math.round(mean * 10) / 10
        }
        return { ...featureSuggestion, mean }
      }),
    [feature_suggestions?.data]
  )

  return (
    <div className={`${show ? 'flex flex-col' : 'hidden'} h-full`}>
      <AddSuggestion profile_id={profile_id} boardId={boardId} />

      <ScrollArea className="mt-4">
        <Column className="gap-3 mb-5">
          {data?.map((featureSuggestion, i) => {
            return (
              <Suggestion
                boardId={boardId}
                profile_id={profile_id}
                key={i}
                featureSuggestion={featureSuggestion}
              />
            )
          })}
        </Column>
      </ScrollArea>
    </div>
  )
}
