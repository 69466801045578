import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useFeatureSuggestionsApi } from 'src/api/hooks/apiFeatureSuggestions'
import { Column } from 'src/shared/components/Semantic/all'
import { Suggestion } from 'src/shared/components/Suggestion'
import { Card, ScrollArea } from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'

import { initialColumns } from '../consts'
import { Header } from './Header'
import { NewSuggestionModal } from './NewSuggestionModal'

export const Board = ({ boardId }) => {
  const { session } = useAuth()
  const { board } = useBoardsApi({
    boardId,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { feature_suggestions, updateFeatureSuggestion } =
    useFeatureSuggestionsApi(boardId || '')

  const onDragEnd = (result) => {
    if (!result.destination) return

    const { destination } = result
    const suggestionId = result.draggableId
    const newStatus = initialColumns[destination.droppableId].status

    updateFeatureSuggestion.mutate({ id: suggestionId, status: newStatus })
  }

  return (
    <>
      <Column className="w-full h-[77vh] overflow-hidden">
        <Header setIsModalOpen={setIsModalOpen} board={board?.data as any} />

        <ScrollArea scrollbarHide className="mt-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex gap-4">
              {Object.entries(initialColumns).map(([columnId, column]) => {
                return (
                  <Droppable droppableId={[columnId]} key={columnId}>
                    {(provided) => (
                      <Card
                        className="bg-neutral-100 p-3"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <h2 className="font-normal mb-2">{column.title}</h2>

                        <Column className="min-h-[200px] w-[260px] gap-3">
                          {feature_suggestions?.data
                            ?.filter(
                              (featureSuggestion) =>
                                featureSuggestion.status === column.status
                            )
                            .map((featureSuggestion, index) => (
                              <Draggable
                                key={featureSuggestion.id}
                                draggableId={featureSuggestion.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="relative"
                                  >
                                    <Suggestion
                                      boardId={board?.data?.id}
                                      featureSuggestion={
                                        featureSuggestion as any
                                      }
                                      profile_id={session?.user?.id || ''}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </Column>
                      </Card>
                    )}
                  </Droppable>
                )
              })}
            </div>
          </DragDropContext>
        </ScrollArea>
      </Column>

      <NewSuggestionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        boardId={boardId}
      />
    </>
  )
}
