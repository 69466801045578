import { Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import {
  FeatureSuggestion_,
  useFeatureSuggestionsApi,
} from 'src/api/hooks/apiFeatureSuggestions'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { useIsMyBoard } from 'src/api/utils/functions'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Dialog, DialogContent } from 'src/shared/components/ui'
import { DialogHeader } from 'src/shared/components/ui/dialog'
import { Image } from 'src/shared/components/ui/image'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'
import { Separator } from 'src/shared/components/ui/separator'
import { useAuth } from 'src/shared/hooks/authProvider'

import { ChatSection } from './Widget/compontents/Suggestions/components/ChatSection'
import { FeatureStatus } from './Widget/compontents/Suggestions/components/FeatureStatus'
import { Modal } from './Widget/compontents/Suggestions/components/Modal'
import { VotingButton } from './Widget/compontents/Suggestions/components/VotingButton'

export const Suggestion = ({
  featureSuggestion,
  boardId,
  profile_id,
}: {
  featureSuggestion: FeatureSuggestion_
  boardId
  profile_id
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpenChange = (open: boolean) => setIsOpen(open)
  const onOpen = () => setIsOpen(true)
  const { session } = useAuth()
  const { board } = useBoardsApi({
    boardId,
  })
  const isMyBoard = useIsMyBoard(board?.data?.profile_id, session?.user?.id)
  const { profile } = useProfilesApi(featureSuggestion.profile_id)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { deleteFeatureSuggestion } = useFeatureSuggestionsApi(boardId)

  const images = featureSuggestion.image_urls
    ?.split(',')
    .filter((image) => image)

  return (
    <>
      {images?.length ? (
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} images={images} />
      ) : null}

      <Card className="p-4">
        <Column className="mb-1">
          <Row className="justify-between items-center mb-1">
            <p className="text-xs text-muted-foreground">
              {profile?.data?.email}
            </p>

            {(isMyBoard || featureSuggestion.profile_id === profile_id) && (
              <Trash2
                className="h-4 w-4 cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              />
            )}
          </Row>

          <div className="text-sm font-medium">{featureSuggestion.title}</div>
        </Column>

        <div className="text-sm">{featureSuggestion.description}</div>

        {images?.length ? (
          <ScrollArea scrollbarHide className="h-[150px] mt-2">
            <Column className="gap-3 items-center">
              {images.map((image, i) => (
                <Image
                  key={i}
                  className="cursor-pointer mx-auto rounded-md"
                  alt="Image illustration"
                  src={image}
                  onClick={onOpen}
                />
              ))}
            </Column>
          </ScrollArea>
        ) : null}

        <Row className="gap-2 mt-2 items-center justify-between w-full">
          <VotingButton
            featureSuggestion={featureSuggestion}
            profile_id={profile_id}
            score={featureSuggestion.feature_suggestions_votes?.reduce(
              (sum, vote) => sum + (vote?.score || 0),
              0
            )}
          />

          <FeatureStatus
            featureSuggestion={featureSuggestion}
            boardId={boardId}
            isMyBoard={isMyBoard}
          />
        </Row>
        <Separator className="my-3" />

        <ChatSection
          featureSuggestion={featureSuggestion}
          isMyBoard={isMyBoard}
          profile_id={profile_id}
        />
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-lg">
          <DialogHeader>Delete suggestion</DialogHeader>
          <Separator />

          <p>Are you sure you want to delete this suggestion?</p>
          <Button
            className="w-fit mt-4"
            variant="destructive"
            onClick={() => {
              deleteFeatureSuggestion.mutate(featureSuggestion.id)
              setIsModalOpen(false)
            }}
          >
            Delete
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
