import { useNewsApi } from 'src/api/hooks/apiNews'
import { VideoPost } from 'src/pages/Board/components/VideoPost'

import { ScrollArea } from '../../ui'
import { LeftBubble } from './LeftBubble'

export const News = ({ show, boardId }: { show: boolean; boardId: string }) => {
  const { news } = useNewsApi(boardId)

  const messages = news?.data?.map((post) => {
    return (
      <div key={post.id} className="flex justify-between">
        {post.type === 'video' ? (
          <VideoPost
            key={post.id}
            src={post.text_content || ''}
            created_at={post.created_at}
          />
        ) : (
          <LeftBubble
            key={post.id}
            created_at={post.created_at}
            text_content={post.text_content || ''}
            title={'Admin'}
            avatarUrl={''}
            // title={post?.user?.first_name || ''}
            // avatarUrl={post?.user?.avatar_url}
          />
        )}
      </div>
    )
  })

  return (
    <div className={`${show ? 'flex flex-col' : 'hidden'} h-full`}>
      <ScrollArea>{messages}</ScrollArea>
    </div>
  )
}
