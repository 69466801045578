import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

export type InsertNews = Database['public']['Tables']['news']['Insert']

export const useNewsApi = (boardId: any) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const { data: news } = useQuery({
    queryKey: ['news', boardId],
    queryFn: async () =>
      await supabase
        .from('news')
        .select('*')
        .eq('board_id', boardId)
        .order('created_at', { ascending: false }),
    enabled: !!boardId,
  })

  const addPost = useMutation({
    mutationFn: async ({ text_content, type, profile_id }: InsertNews) =>
      await supabase.from('news').insert([
        {
          text_content,
          board_id: boardId,
          type,
          profile_id,
        },
      ]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] })
    },
  })

  const deletePost = useMutation({
    mutationFn: async (id: number) =>
      await supabase.from('news').delete().eq('id', id).eq('board_id', boardId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] })
    },
  })

  return {
    news,
    addPost,
    deletePost,
  }
}
