import { SupabaseClient, createClient } from '@supabase/supabase-js'
import { ReactNode, createContext, useContext, useState } from 'react'
import { Database } from 'supabase/database.types'

const SupabaseClientContext = createContext<SupabaseClient<Database> | null>(
  null
)
const isDev = process.env.NODE_ENV === 'development'

const REACT_APP_SUPABASE_URL = isDev
  ? process.env.REACT_APP_SUPABASE_URL
  : process.env.REACT_APP_SUPABASE_URL_PRODUCTION

const REACT_APP_SUPABASE_ANON_KEY = isDev
  ? process.env.REACT_APP_SUPABASE_ANON_KEY
  : process.env.REACT_APP_SUPABASE_ANON_KEY_PRODUCTION

export function SupabaseProvider(props: { children: ReactNode }) {
  const [client] = useState(() =>
    createClient<Database>(
      REACT_APP_SUPABASE_URL!,
      REACT_APP_SUPABASE_ANON_KEY!
    )
  )

  return (
    <SupabaseClientContext.Provider value={client}>
      {props.children}
    </SupabaseClientContext.Provider>
  )
}

export function useSupabaseClient() {
  const client = useContext(SupabaseClientContext)

  if (client === null) {
    throw new Error(
      'Supabase client not provided via context.\n' +
        'Did you forget to wrap your component tree with SupabaseProvider?'
    )
  }

  return client
}
