import { isEmail, useForm } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { Mail, X } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import { useToast } from 'src/shared/hooks/use-toast'

export const LoginOverlay = () => {
  const [loading, setLoading] = useState(false)
  const supabaseClient = useSupabaseClient()
  const [success, setSuccess] = useState(false)
  const { toast } = useToast()
  const { board_id } = useParams()

  const form = useForm({
    initialValues: {
      notSoSecretKey: '',
      email: '',
    },
    validate: {
      email: isEmail('Invalid email'),
    },
  })

  const handleLogin = async () => {
    form.validate()
    if (!form.isValid()) return
    setLoading(true)

    const { error } = await supabaseClient.auth.signInWithOtp({
      email: form.values.email.toLowerCase(),
      options: {
        emailRedirectTo: `${process.env.REACT_APP_FE_SERVER_URL}/public-board/${board_id}`,
      },
    })

    if (error) {
      const msg = JSON.stringify(error.message).slice(0, 250)
      const message = msg.includes('Signups not allowed for otp')
        ? 'You need to create a user'
        : msg

      toast({
        variant: 'destructive',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <X className="h-4 w-4" />
            {message || 'Something went wrong'}
          </Row>
        ),
      })
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  return (
    <div className="flex items-center justify-center py-12">
      <Card className="mx-auto grid gap-6 w-[350px] p-6 glass-thin">
        <Column className="gap-2">
          <p className="text-balance text-muted-foreground">
            Sign in/sign up so that you can add your feature suggestions! 🎉
          </p>
        </Column>

        {success ? (
          <Card className="p-4">
            <Column>
              <p className="text-balance">Success! ✅</p>
              <p className="text-balance">
                To login, click the link in your email.
              </p>
            </Column>
          </Card>
        ) : (
          <Column className="gap-4">
            <Input
              label="Email"
              autoComplete="email"
              style={{ flex: 1 }}
              {...form.getInputProps('email')}
              placeholder="Your email"
              onKeyDown={getHotkeyHandler([['Enter', handleLogin]])}
            />

            <Button
              isLoading={loading}
              onClick={handleLogin}
              disabled={!form.values.email}
            >
              Enter
              <Mail className="h-4 w-4 ml-2" />
            </Button>
          </Column>
        )}
      </Card>
    </div>
  )
}
