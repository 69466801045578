/* eslint-disable jsx-a11y/media-has-caption */
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const VideoPost = ({
  src,
  key,
  created_at,
}: {
  src: string
  key: any
  created_at: string
}) => {
  return (
    <div key={key} className="col w-full p-2">
      <video controls className="rounded-xl mt-3 mb-2">
        <source src={src} type="video/mp4" />
      </video>

      <span className="text-xs font-normal ml-2">
        {dayjs(created_at).toNow()}
      </span>
    </div>
  )
}
