export enum StatusMap {
  waiting = 'Waiting',
  investigating = 'Investigating',
  building = 'Building',
  done = 'Done',
}

export enum StatusColorMap {
  waiting = 'text-yellow-400',
  investigating = 'text-orange-500',
  maybeLater = 'text-yellow-500',
  building = 'text-red-500',
  done = 'text-green-500',
}
