import html2canvas from 'html2canvas'
import { useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useSupabaseClient } from 'src/api/supabase'
import { Button } from 'src/shared/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from 'src/shared/components/ui/dialog'

export const ScreenshotTool = ({ isModalOpen, setModalOpen, setImageUrl }) => {
  const supabase = useSupabaseClient()
  const { orgId } = useOrgIdApi()

  const [screenshot, setScreenshot] = useState<string | null>(null)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth * 0.8,
    height: window.innerHeight * 0.8,
  })
  const canvasRef = useRef<CanvasDraw | null>(null)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.8,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const captureScreenshot = async () => {
    const div = document.getElementById('widget-container')
    if (div) {
      div.style.display = 'none'
    }

    const canvas = await html2canvas(document.body, {
      useCORS: true,
      scale: window.devicePixelRatio,
    })
    setScreenshot(canvas.toDataURL('image/png'))

    if (div) {
      div.style.display = 'inherit'
    }
  }

  useEffect(() => {
    if (isModalOpen && !screenshot) {
      captureScreenshot()
    }
  }, [isModalOpen])

  const saveImage = async () => {
    if (canvasRef.current && screenshot) {
      // Create a new canvas to combine the screenshot and drawings
      const combinedCanvas = document.createElement('canvas')
      const ctx = combinedCanvas.getContext('2d')
      if (!ctx) return

      // Set the canvas size to match the screenshot
      const img = new Image()
      img.src = screenshot
      await new Promise((resolve) => {
        img.onload = resolve
      })
      combinedCanvas.width = img.width
      combinedCanvas.height = img.height

      // Draw the screenshot
      ctx.drawImage(img, 0, 0)

      // Draw the CanvasDraw content on top
      const drawingCanvas = canvasRef.current.canvasContainer
        .children[1] as HTMLCanvasElement
      const drawingCtx = drawingCanvas.getContext('2d')
      if (!drawingCtx) return

      // Calculate the scale and offset
      const scaleX = img.width / drawingCanvas.width
      const scaleY = img.height / drawingCanvas.height

      // Apply the scale and draw the content
      ctx.scale(scaleX, scaleY)
      ctx.drawImage(drawingCanvas, 0, 0)

      // Reset the scale
      ctx.setTransform(1, 0, 0, 1, 0, 0)

      // Get the combined image data
      const dataUrl = combinedCanvas.toDataURL('image/png')
      const res = await fetch(dataUrl)
      const blob = await res.blob()

      const fileName = `${orgId}/screenshot_${Date.now()}.png`
      const { data, error } = await supabase.storage
        .from('screenshots')
        .upload(fileName, blob)

      if (error) {
        console.error('Error uploading image:', error)
        return
      }

      if (data) {
        const {
          data: { publicUrl },
        } = supabase.storage.from('screenshots').getPublicUrl(data.path)

        if (!data.path) {
          console.error('Error getting public URL:', data)
          return
        }

        console.log('Full response:', data)
        setImageUrl(publicUrl)
        console.log('Image uploaded successfully. URL:', publicUrl)
      }
    }

    setModalOpen(false)
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
      <DialogContent className="max-w-[90vw] gap-4">
        <div
          style={{
            width: `${windowSize.width}px`,
            height: `${windowSize.height}px`,
          }}
          className="w-full h-full"
        >
          <CanvasDraw
            ref={canvasRef}
            imgSrc={screenshot || undefined}
            canvasWidth={windowSize.width}
            canvasHeight={windowSize.height}
            brushRadius={2}
            lazyRadius={4}
          />
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => setModalOpen(false)}>
            Close
          </Button>

          <Button onClick={saveImage}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
