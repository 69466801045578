import { Circle } from 'lucide-react'
import { Badge } from 'src/shared/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'

import { useFeatureSuggestionsApi } from '../../../../../../api/hooks/apiFeatureSuggestions'
import { StatusColorMap, StatusMap } from './consts'

export const FeatureStatus = ({
  featureSuggestion,
  boardId,
  isMyBoard,
}: {
  featureSuggestion
  boardId
  isMyBoard?: boolean
}) => {
  const { updateFeatureSuggestion } = useFeatureSuggestionsApi(boardId)

  const handleStatusChange = (status: string) => {
    updateFeatureSuggestion.mutate({
      status,
      id: featureSuggestion.id,
    })
  }

  return isMyBoard ? (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Badge variant="secondary">
          {StatusMap[featureSuggestion.status]}

          <Circle
            className={`ml-2 ${StatusColorMap[featureSuggestion.status]}`}
            style={{
              width: 10,
              height: 10,
              fill: 'currentColor',
            }}
          />
        </Badge>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => handleStatusChange('waiting')}>
          Waiting
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleStatusChange('investigating')}>
          Investigating
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleStatusChange('building')}>
          Building
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleStatusChange('done')}>
          Done
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Badge>
      {StatusMap[featureSuggestion.status]}

      <Circle
        className={`ml-2 ${StatusColorMap[featureSuggestion.status]}`}
        style={{
          width: 10,
          height: 10,
          fill: 'currentColor',
        }}
      />
    </Badge>
  )
}
