import { MessageSquare, Settings, Trello, UsersRound } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Suggestions as SuggestionsMobile } from '../../shared/components/Widget/compontents/Suggestions'
import { Board } from './components/Board'
import { LeadsTab } from './components/LeadsTab'
import { NewSuggestionModal } from './components/NewSuggestionModal'
import { NewsTabs } from './components/NewsTab'
import { SettingsTab } from './components/SettingsTab'

export const BoardPage = () => {
  const { boardId } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { session } = useAuth()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const navigate = useNavigate()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState('board')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tab = searchParams.get('tab')

    if (tab && ['board', 'leads', 'news', 'settings'].includes(tab)) {
      setActiveTab(tab)
    } else {
      // Set default tab in URL if not present
      navigate(`?tab=board`, { replace: true })
    }
  }, [location, navigate])

  const handleTabChange = (value: string) => {
    setActiveTab(value)
    navigate(`?tab=${value}`)
  }

  if (isMobile) {
    return (
      <Column className="w-full h-full">
        <SuggestionsMobile show profile_id={session?.user?.id || ''} />
      </Column>
    )
  }

  return (
    <>
      <Tabs
        value={activeTab}
        onValueChange={handleTabChange}
        className="w-full h-full"
      >
        <TabsList className="w-full">
          <TabsTrigger value="board">
            <Trello className="h-4 w-4 mr-2" /> Board
          </TabsTrigger>

          <TabsTrigger value="leads">
            <UsersRound className="h-4 w-4 mr-2" /> Leads
          </TabsTrigger>

          <TabsTrigger value="news">
            <MessageSquare className="h-4 w-4 mr-2" /> News
          </TabsTrigger>

          <TabsTrigger value="settings">
            <Settings className="h-4 w-4 mr-2" /> Settings
          </TabsTrigger>
        </TabsList>

        <TabsContent value="board">
          <Card className="h-full">
            <Board boardId={boardId} />
          </Card>
        </TabsContent>

        <TabsContent value="settings">
          <SettingsTab boardId={boardId} />
        </TabsContent>

        <TabsContent value="leads">
          <LeadsTab boardId={boardId} />
        </TabsContent>

        <TabsContent value="news">
          <NewsTabs boardId={boardId} />
        </TabsContent>
      </Tabs>

      <NewSuggestionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        boardId={boardId}
      />
    </>
  )
}
