import { Link, PlusCircle } from 'lucide-react'
import { Board } from 'src/api/hooks/apiBoards'
import { lightenColor } from 'src/pages/Boards/components/BoardCard'
import { paths } from 'src/routes/paths'
import { Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Tooltip } from 'src/shared/components/ui'

export const Header = ({
  board,
  setIsModalOpen,
}: {
  board: Board
  setIsModalOpen: (open: boolean) => void
}) => {
  const lightenedColor = lightenColor(board?.color || '#000000', 50)
  // const isDev = process.env.NODE_ENV === 'development'

  // navigator.clipboard.writeText(
  //   `${isDev ? 'http://127.0.0.1:3000' : process.env.REACT_APP_FE_SERVER_URL}${
  //     paths.publicBoard
  //   }/${board?.id}`
  // )

  return (
    <Card className="flex row justify-between items-center p-2">
      <Row c className="gap-2">
        <h3 className="text-lg font-normal">{board?.name}</h3>

        <div
          className="w-5 h-5 rounded-full"
          style={{
            background: `linear-gradient(to right, ${
              board?.color || '#000000'
            }, ${lightenedColor})`,
          }}
        />
      </Row>

      <Row c className="gap-2">
        <Tooltip content="Share link to community board">
          <Button
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_FE_SERVER_URL}${paths.publicBoard}/${board?.id}`
              )
              const button = document.activeElement as HTMLButtonElement
              button.classList.add('bg-neutral-500', 'text-white')
              setTimeout(() => {
                button.classList.remove('bg-neutral-500', 'text-white')
              }, 1000)
            }}
          >
            <Link className="w-4 h-4" />
          </Button>
        </Tooltip>

        <Button color="primary" onClick={() => setIsModalOpen(true)}>
          <PlusCircle className="w-4 h-4 mr-2" />
          Add suggestion
        </Button>
      </Row>
    </Card>
  )
}
