import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowRight, Save } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useSupabaseClient } from 'src/api/supabase'
import { FormInput, FormWrapper } from 'src/shared/components/FormWrapper'
import { Column } from 'src/shared/components/Semantic/all'
import { Widget } from 'src/shared/components/Widget'
import {
  Button,
  Card,
  CardTitle,
  ScrollArea,
  Separator,
} from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'
import { z } from 'zod'

import { useOrgApi } from '../../api/hooks/apiOrgs'
import { useProfilesApi } from '../../api/hooks/apiProfiles'
import { paths } from '../../routes/paths'

const formSchema = z.object({
  first_name: z.string().min(2).max(50),
  last_name: z.string().min(2).max(50),
  email: z.string().email(),
  avatar_url: z.string().optional(),
})

export const SettingsPage = () => {
  const { session } = useAuth()
  const { orgId } = useOrgIdApi()
  const { profile, updateProfile } = useProfilesApi(session?.user?.id)
  const { organizations } = useOrgApi(orgId)
  const navigate = useNavigate()
  const supabase = useSupabaseClient()

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      avatar_url: '',
    },
  })

  useEffect(() => {
    const profile_ = profile?.data
    if (profile_) {
      methods.setValue('first_name', profile_.first_name || '')
      methods.setValue('last_name', profile_.last_name || '')
      methods.setValue('email', profile_.email || '')
      methods.setValue('avatar_url', profile_.avatar_url || '')
    }
  }, [profile?.data, organizations?.data])

  const onSubmitUserSettings = (values: z.infer<typeof formSchema>) =>
    updateProfile.mutate({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      avatar_url: values.avatar_url,
      id: session?.user.id!,
    })

  const handleLogout = async () => {
    await supabase.auth.signOut()
    navigate(paths.loginSignUp)
  }

  const UserSettings = () => (
    <Card className="w-[700px] justify-center items-center">
      <CardTitle>User settings</CardTitle>
      <Separator />

      <Column className="gap-2 w-full mt-4">
        <FormWrapper onSubmit={onSubmitUserSettings} methods={methods}>
          <FormInput name="first_name" label="First name" methods={methods} />
          <FormInput name="last_name" label="Last name" methods={methods} />
          <FormInput name="email" label="Email" methods={methods} />
          <FormInput name="avatar_url" label="Avatar URL" methods={methods} />

          <Button style={{ marginTop: '1rem' }} type="submit">
            <Save className="w-4 h-4 mr-2" />
            Save
          </Button>
        </FormWrapper>
      </Column>
    </Card>
  )

  const Actions = () => (
    <Card className="w-[700px] justify-center items-center">
      <h2 className="text-xl font-normal mb-2">Other</h2>

      <Button onClick={handleLogout} className="mt-4">
        Log out
        <ArrowRight className="h-4 w-4 ml-2" />
      </Button>
    </Card>
  )

  return (
    <>
      <ScrollArea scrollbarHide>
        <Column c className="gap-4">
          <UserSettings />
          <Actions />
        </Column>
      </ScrollArea>

      <Widget boardId={'4441845d-a7da-4935-b67b-0fd76da888db'} />
    </>
  )
}
